import request from '@/utils/request'

export function orderInfoStatusList(params) {
  return request({
    url: '/order-info/status-list',
    method: 'get',
    params
  })
}

export function orderInfoList(params) {
  return request({
    url: '/order-info/list',
    method: 'get',
    params
  })
}

export function orderInfoView(params) {
  return request({
    url: '/order-info/view',
    method: 'get',
    params
  })
}

export function downloadQuoteOrder(data) {
  return request({
    url: '/order-info/download-quote-order',
    method: 'post',
    data
  })
}
