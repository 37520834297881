<template>
  <div class="good">
    <router-link class="good-banner" :to="`/goodsDetail?id=${item.goods.id}`">
      <img :src="item.goods.img_url" alt="" />
    </router-link>
    <div class="good-info">
      <router-link class="good-name" :to="`/goodsDetail?id=${item.goods.id}`">{{
        item.goods_title
      }}</router-link>
      <div class="good-price notranslate">{{ priceFormat(item.price, item.currency) }}</div>
      <!-- <el-button class="good-btn" type="primary" plain>加入购物车</el-button> -->
    </div>
    <div class="good-number">x{{ item.goods_number }}</div>
    <div class="good-number">商品小计:<p class="notranslate">{{ priceFormat(item.price*item.goods_number, item.currency) }}</p></div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import currencyFormatter from '@/utils/currencyFormatter'

const priceFormat = (price, currency = 'CNY') => {
  return currencyFormatter.format(price, { currency: currency})
}

const props = defineProps({
  item: {
    type: [Object],
    default: () => ({})
  }
})
const disabled = ref(false)
const disabledStatusFalse = (count) => {
  setTimeout(() => {
    disabled.value = false
  }, count * 1000)
}
const onAdd = () => {
  const { id } = props.item
  disabled.value = true
  disabledStatusFalse(20)
  cartCreate({
    goods_alias: id
    // goods_number: num.value
  })
    .then((res) => {
      disabledStatusFalse(1)
      const { error, msg } = res
      if (error == 0) {
        ElNotification({
          title: msg || 'success'
        })
        store.dispatch('app/getCartList')
      }
    })
    .catch(() => {
      disabledStatusFalse(1)
    })
}
</script>

<style lang="scss" scoped>
.good {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    border-bottom: none;
  }

  &-banner {
    width: 100px;
    height: 100px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    padding-left: 20px;
    position: relative;
    padding-bottom: 40px;
    color: #999;
    width: 200px;
  }

  .good-number {
    margin-left: 50px;
    flex: 1;
    text-align: right;
  }

  &-name {
    width: 95%;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }

  &-btn {
    position: absolute;
    bottom: 0;
    left: 20px;
  }
}
</style>
